// Constantes
import { MENUS_TYPES } from '@/constants'
// Components
import CategoriesList from '@/views/categories/CategoriesList'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import takeAwayMixin from '../../mixins/takeAwayMixin'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getEveryMenusByPlaceId } from '@/services/category'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'TakeAwayCategoriesList',
  components: { CategoriesList, VuetifyContentLoading },
  mixins: [uiMixin, takeAwayMixin],
  data() {
    return {
      takeAwayMenuId: null,
      // Others
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('takeAway', ['takeAwayHasMenu'])
  },
  watch: {
    // Comprobamos si el usuario tiene menu "takeAway"
    takeAwayHasMenu: {
      async handler(value) {
        // loading
        this.processingRequest = true
        try {
          if (!isNil(value)) {
            if (value) {
              await this.setTakeAwayMenuId()
            } else {
              this.showDialogInitialTakeAwaySetup()
            }
          }
        } catch (error) {
          this.modifyAppAlert({
            text: error.message,
            type: 'error',
            visible: true
          })
        } finally {
          // loading
          this.processingRequest = false
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Establececemos la variable "takeAwayMenuId" con el
     * valor del menú o carta de tipo "takeAway"
     */
    async setTakeAwayMenuId() {
      // Obtenemos todos los datos de las distintas
      // cartas que el establecimiento posee
      const menus = await getEveryMenusByPlaceId(this.placeData.id)

      if (menus.length === 0) {
        throw new Error('Ha sido imposible obtener los datos de los menús o cartas indicados')
      }

      // Obtenemos solo los menus tipo "takeAway"
      const takeAwayMenus = menus.filter((menu) => {
        return menu.type === MENUS_TYPES.takeAway.value
      })

      if (takeAwayMenus.length === 0) {
        // TODO - mostrar modal de configuración básica
        throw new Error(
          'No posee ninguna carta de TakeAway, póngase en contacto con el proveedor del servicio'
        )
      }

      this.takeAwayMenuId = takeAwayMenus[0].id
    }
  }
}
